import React, { Component } from 'react';
import { connect } from 'react-redux';
import { match } from "react-router-dom";
import { Table, Icon } from "antd-min";
import { TableProps } from "antd/lib/table";
import { unmaskThrottle } from 'gl-commonui';
import { StateType } from '@app/states';
import { CampusModel } from '@app/service/school/resource';
import { getCampusClassList, queryCampusClassList, approveByClass } from '@app/states/cims';
import { ClassesTable } from './classes-table';
import { CampusInfoRow } from './campus-info-row';
import { Wijmo2ExcelHelper, DateHelper } from '@app/util';
import { PendingChangePropsModel } from '@app/service/cims';

class CampusesTable extends Table<CampusModel> { }

interface CampusTableProps {
    campusClassLoading?: {}
    tableProps?: TableProps<CampusModel>
    routeMatch?: match<any>
    campusClasses?: {}
    campusClasses4Display?: {}
    getCampusClassList?: (query) => void
    queryCampusClassList?: (query) => void
    approveByClass?: (query) => void
}

@connect(({campus:{ campusClasses, campusClasses4Display }, cims:{ campusClassLoading }}: StateType)=>(
    { campusClasses, campusClasses4Display, campusClassLoading }), 
    { getCampusClassList, queryCampusClassList, approveByClass }
)
export class CampusTable extends Component<CampusTableProps, {expandedRowKeys: string[]}> {
    private expandedRowKeysSet: Set<string> = new Set<string>();
    state = { expandedRowKeys : [] }

    componentWillReceiveProps(nextProps) {
        if (this.props.tableProps.dataSource != nextProps.tableProps.dataSource) {
            const campusId = nextProps.tableProps.dataSource.length > 0 ? nextProps.tableProps.dataSource[0].id : null;
            const { getCampusClassList, routeMatch: { params: {schoolId}} } = nextProps;
            if (campusId) {
                this.expandedRowKeysSet.add(campusId);
                this.setState({ expandedRowKeys: [campusId] }, () => getCampusClassList({schoolId, campusId}));
            }
        }
    }

    onExpandCampus(expanded: boolean, record: CampusModel) {
        const { getCampusClassList, routeMatch: { params: {schoolId}} } = this.props;
        const campusId = record.id;
        expanded && getCampusClassList({schoolId, campusId});
        this.expandedRowKeysSet.has(campusId) ? this.expandedRowKeysSet.delete(campusId) : this.expandedRowKeysSet.add(campusId);
        this.setState({
            expandedRowKeys: Array.from(this.expandedRowKeysSet.values())
        });
    }

    renderCampusContent = (value, campus, index) => {
        const obj = {
            children: value,
            props: {
                colSpan: 0
            },
        };
        return obj;
    };

    setCampusColumn(columns: any[], routeMatch: match<any>) {
        const campusNameColumn = columns.find(column => column.dataIndex == 'name');
        columns.forEach(column => column.render = this.renderCampusContent);
        if (campusNameColumn) {
            campusNameColumn.render = (text, campus, index) => {
                return {
                    children: <CampusInfoRow {...{...campus, ...routeMatch}}/>,
                    props: {
                        colSpan: columns.length
                    },
                };
            }
        }
        return columns;
    }

    onFormatExportCellData(dataItem, column, cellData, cell) {
        if (column.dataIndex == PendingChangePropsModel.startDate4Display || column.dataIndex.indexOf('plansIndexed') >=0 ) {
            return DateHelper.formatDate2Local(cellData);
        }
        else {
            return cellData;
        }
    }

    // onExport({campusName, columns, exportData}) {
    //     Wijmo2ExcelHelper.export2Excel(exportData, 
    //         columns, 
    //         `${campusName}.xlsx`,
    //         null,
    //         this.onFormatExportCellData,
    //         (base64) => unmaskThrottle(),
    //         (reason) => unmaskThrottle(),
    //     );
    // }

    render() {
        const { tableProps: campusTableProps, campusClassLoading, campusClasses, campusClasses4Display, routeMatch, queryCampusClassList, approveByClass } = this.props;
        return (
            <React.Fragment>
                <CampusesTable
                    showHeader={false}
                    expandIcon={(props) => props.expanded ? 
                        <div onClick={(e) => props.onExpand(props.record, e.nativeEvent)}><Icon type="up" /></div>  : 
                        <div onClick={(e) => props.onExpand(props.record, e.nativeEvent)}><Icon type="down" /></div>}
                    expandedRowKeys={this.state.expandedRowKeys}
                    expandIconColumnIndex={0}
                    expandIconAsCell={false}
                    expandedRowRender={(campus:CampusModel) => 
                        <ClassesTable 
                            loading={campusClassLoading[campus.id]}
                            campus={campus}
                            campusClasses={campusClasses[campus.id]}
                            campusClasses4Display={campusClasses4Display[campus.id]}
                            onQuery={queryCampusClassList} 
                            // onExport={this.onExport.bind(this)}
                            onApprove={(query) => approveByClass(query)}
                        />
                    }
                    onExpand={this.onExpandCampus.bind(this)}
                    {...{...campusTableProps, columns: this.setCampusColumn(campusTableProps.columns, routeMatch)} }
                />
            </React.Fragment>
        )
    }
}
