import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Table, Divider } from "antd-min";
import { EventModalContentProps, UnitPlanApproveDenyModel } from '@app/service/cims';
import { fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { ArrowRightIcon } from '@app/components/svgicon';
import { FormItem } from './cims-consts';

export const UnitPlan4ApproveDeny: React.FC<EventModalContentProps<UnitPlanApproveDenyModel>> = (props: EventModalContentProps<UnitPlanApproveDenyModel>) => {
    const { item } = props;
    // return ( item.changeData ?
    //     <div className='unit-plan-approve-deny'>
    //         <h5>{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrentUnitPlan})}</h5>
    //         <UnitPlanTable logs={item} dataIndex={0}/>
    //         <h5>{fmtMsg({id:SchoolLocale.CIMSChangeEventPreviousUnitPlan})}</h5>
    //         <UnitPlanTable logs={item} dataIndex={1} />
    //     </div> : null
    // )
    return ( item.changeData ?
        <div className='unit-plan'>
            <FormItemsLayout colTotal={2} >
                <div className='unit-plan-divider'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>
            </FormItemsLayout>            
            <FormItemsLayout colTotal={2} >
                <FormItem>
                    <UnitPlanTable logs={item} dataIndex={1}/>
                </FormItem>
                <ArrowRightIcon className='previous-current-comparer-arrow' />
                <FormItem>
                    <UnitPlanTable logs={item} dataIndex={0}/>
                </FormItem>
            </FormItemsLayout>
        </div>  : null
    )
}

function getColumns(data) {
    return [
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanName }),
            dataIndex: 'unitTitle',
            width: '35%'
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanStartDate }),
            dataIndex: 'startDate',
            width: '55%'
        }        
    ];
}

const UnitPlanTable = ({logs, dataIndex}) => {
    return (
        <Table
            rowKey='index'
            pagination={false}
            rowClassName={(data, index) => {
                return data['changed'] ? 'unit-plan-changed' : '';
            }}
            scroll={{y: 310}}
            //dataSource={logs.changeData.filter((cd, index) => index == dataIndex && Object.keys(cd).filter(key => (key.indexOf('U') >=0 || key.indexOf('LS') >=0)).length > 0)}
            //dataSource={logs.unitColumns}
            dataSource={dataIndex == 0 ? logs.currentUnits : logs.previousUnits}
            columns={getColumns(logs.changeData.filter((cd, index) => index == dataIndex && Object.keys(cd).filter(key => (key.indexOf('U') >=0 || key.indexOf('LS') >=0)).length > 0))}
        />
    )
}