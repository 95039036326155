import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Table, Divider } from "antd-min";
import { EventModalContentProps, UnitPlanChangeEventModel } from '@app/service/cims';
import { fmtMsg, DateHelper } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout } from './cims-consts';
import { ArrowRightIcon } from '@app/components/svgicon';

export const UnitPlan: React.FC<EventModalContentProps<UnitPlanChangeEventModel>> = (props: EventModalContentProps<UnitPlanChangeEventModel>) => {
    const { item } = props;
    const sortUnitPlan = (unitPlan) => {
        return unitPlan ? unitPlan.sort((a, b)=> Math.abs(a.unit) - Math.abs(b.unit)) : [];
    }
    return (
        <div className='unit-plan'>
            <FormItemsLayout colTotal={2} >
                <div className='unit-plan-divider'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>
            </FormItemsLayout>            
            <FormItemsLayout colTotal={2} >
                <FormItem>
                    <UnitPlanTable dataSource={sortUnitPlan(item.previousPlans)} />
                </FormItem>
                <ArrowRightIcon className='previous-current-comparer-arrow' />
                <FormItem>
                    <UnitPlanTable dataSource={sortUnitPlan(item.currentPlans)} isCurrent={true} previousPlans={item.previousPlans} />
                </FormItem>
            </FormItemsLayout>
        </div>
    )
}

export const UnitPlanTable = ({dataSource, isCurrent = false, previousPlans = []}) => {
    return (
        <Table
            rowKey='unit'
            pagination={false}
            scroll={{y: 310}}
            dataSource={dataSource}
            rowClassName={(data:any, index) => {
                const previousPlan = previousPlans.find(plan => plan.unit == data.unit);
                return isCurrent && 
                    ((!previousPlan && data.startDate) 
                     || (previousPlan && previousPlan.startDate != data.startDate)) ? 'unit-plan-changed' : '';
            }}            
            columns={[
                {
                    title: fmtMsg({ id: SchoolLocale.UnitPlanName }),
                    dataIndex: "unit",
                    width: '45%',
                    render: (text, log, index) => {
                        return Math.abs(text);
                    }
                }, {
                    title: fmtMsg({ id: SchoolLocale.UnitPlanStartDate }),
                    dataIndex: "startDate",
                    width: '55%',
                    render: (text, { startDate }) => startDate ? DateHelper.formatDate2Local(startDate) : ''
            }]}
        />        
    )
}