import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { EventModalContentProps, StudentRemovedModel } from '@app/service/cims';
import { formatName, fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout } from './cims-consts';

export const StudentRemoved: React.FC<EventModalContentProps<StudentRemovedModel>> = (props: EventModalContentProps<StudentRemovedModel>) => {
    const { loading } = props;
    if (loading && props.item.student) {
            props.item.student.name = formatName(props.item.student.englishName, props.item.student.name);
    }
    return (
        <div className='student-movement'>
            {props.item.student &&
                <FormItemsLayout colTotal={2} >
                    <FormItem {...FormItemLayout}>
                        <StudentRemovedMessage {...props}/>
                    </FormItem>
                </FormItemsLayout>}
        </div>
    )
}

export const StudentRemovedMessage = (props: EventModalContentProps<StudentRemovedModel>) => {
    const { item, subTitle } = props;
    const student = item.student ? item.student.name : '';
    const message = fmtMsg({id:SchoolLocale.CIMSStudentRemovedEventMessage}, {student: student, schoolClassName: subTitle? subTitle: item.schoolClassName});
    return <span className="ant-form-text" dangerouslySetInnerHTML={{__html: message}}/>
}
