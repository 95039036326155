import { Tagging } from "@app/components/tagging/tagging";
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import {CampusModel} from '@app/service/school/campus/index';
import { TagEntity } from "@app/service/tag-manager";
import { GSAdminAction, fmtMsg } from "@app/util";
import { Col, Row } from "antd";
import { GLGlobal, GLUtil } from "gl-commonui";
import React from "react";
import { match } from "react-router-dom";

export const CampusInfoRow = (props: CampusModel & match<any>) => {
    const {
        id: campusId,
        name,
        phone,
        fullAddress,
        tags,
        params: { regionId, schoolId },
    } = props;
    const pathClasses =
        regionId && schoolId
            ? GLUtil.pathStringify(PathConfig.Classes, {
                  regionId,
                  schoolId,
                  campusId
              })
            : null;
    const hasTagViewPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsView);
    const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
    let colSize = 8;
    if(hasTagViewPermission) {
        colSize = 6;   
    }
    return (
        <Row type="flex" gutter={20} className="campus-info">
            <Col sm={24} md={colSize}>
                <div className="campus-info__item">
                    <span className="campus-info__label">
                        {fmtMsg({ id: SchoolLocale.CampusNameTitle })}
                    </span>
                    <span className="campus-info__value">
                        {pathClasses ? (
                            <a href={`${pathClasses}`}>{name}</a>
                        ) : (
                            name
                        )}
                    </span>
                </div>
            </Col>
            <Col sm={24} md={colSize}>
                <div className="campus-info__item">
                    <span className="campus-info__label">
                        {fmtMsg({ id: SchoolLocale.CampusAddress })}
                    </span>
                    <span className="campus-info__value">{fullAddress}</span>
                </div>
            </Col>
            <Col sm={24} md={colSize}>
                <div className="campus-info__item">
                    <span className="campus-info__label">
                        {fmtMsg({ id: SchoolLocale.CampusPhone })}
                    </span>
                    <span className="campus-info__value">{phone}</span>
                </div>
            </Col>
            { hasTagViewPermission && 
            <Col sm={24} md={colSize}>
                <div className="campus-info__item">
                    <span className="campus-info__label">
                        {fmtMsg({ id: GSAdminLocale.SchoolModelTags })}
                    </span>
                    <span className="campus-info__value">
                    <div className="classname-row__tags">
                        {hasTagViewPermission && regionId && 
                        (
                            <Tagging
                                selectedTags={tags}
                                entityId={campusId}
                                regionId={regionId}
                                entity={TagEntity.Campus}
                                isViewOnly={!hasTagManagerPermission}
                                popoverPlacement="bottomLeft"
                            />
                        )}
                    </div> 
                    </span>
                </div>
            </Col>
            }
        </Row>
    );
};
