import React, { Component } from 'react';
import { Divider } from "antd-min";
import { FormItemsLayout } from 'gl-commonui';
import { fmtMsg, subscriptionTypeUsage } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout4Licenses } from './cims-consts';
import { LicenseChangeTypeContentProps, LicenseUpdatedEventModel, LicenseChangeApproveDenyEventModel } from '@app/service/cims';
import { ArrowRightIcon } from '@app/components/svgicon';

export const LicenseCountComparer: React.FC<LicenseChangeTypeContentProps<LicenseChangeApproveDenyEventModel | LicenseUpdatedEventModel>> = (props:LicenseChangeTypeContentProps<LicenseChangeApproveDenyEventModel | LicenseUpdatedEventModel>) => {
    const { item } = props;
    const {currentLicenses, previousLicenses} = 'currentLicenses' in item ? item as LicenseUpdatedEventModel : {currentLicenses: null, previousLicenses: null};
    const {currentStatus, previousStatus} = 'currentStatus' in item ? item as LicenseChangeApproveDenyEventModel : {currentStatus: null, previousStatus: null};
    const current = currentLicenses || currentStatus;
    const previous = previousLicenses ||  previousStatus;
    const digitalChanged = current &&  previous && current.newDigitalLicenses != previous.newDigitalLicenses;
    const textbookChanged = current &&  previous && current.newTextbookLicenses != previous.newTextbookLicenses;
    const usage = item.subscriptionTypeUsage; 
    return (
        <>
            <FormItemsLayout colTotal={2} >
                <div className='license-count-comparer-divider'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>                
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                {usage != subscriptionTypeUsage.Standard && 
                <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventStudentCount})}>
                    <span className="ant-form-text">{previous && previous.newStudentCount}</span>
                </FormItem>}
                {usage == subscriptionTypeUsage.Standard && 
                <div className="license-count-digital-textbook">
                    {digitalChanged && <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventDigitalLicense})}>
                        <span className="ant-form-text">{previous && previous.newDigitalLicenses}</span>
                    </FormItem>}
                    {textbookChanged && <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventTextbookLicense})}>
                        <span className="ant-form-text">{previous && previous.newTextbookLicenses}</span>
                    </FormItem>}
                </div>}
                <ArrowRightIcon className='license-count-comparer-arrow' />
                {usage != subscriptionTypeUsage.Standard && 
                <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventStudentCount})}>
                    <span className="ant-form-text">{current && current.newStudentCount}</span>
                </FormItem>}
                {usage == subscriptionTypeUsage.Standard && 
                <div className="license-count-digital-textbook">
                    {digitalChanged && <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventDigitalLicense})}>
                        <span className="ant-form-text">{current && current.newDigitalLicenses}</span>
                    </FormItem>}
                    {textbookChanged && <FormItem {...FormItemLayout4Licenses} label={fmtMsg({id:SchoolLocale.CIMSChangeEventTextbookLicense})}>
                        <span className="ant-form-text">{current && current.newTextbookLicenses}</span>
                    </FormItem>}
                </div>} 
            </FormItemsLayout>
        </>
    )
}