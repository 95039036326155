import React from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { EventModalContentProps, LicenseUpdatedEventModel } from '@app/service/cims';
import { fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout4LableText } from './cims-consts';
import { LicenseCountComparer } from './license-count-comparer';
interface NoteObj {
    Key: string;
    Parameter: {
        AdditionalNote: string | null;
        Count: number | null;
        Name: string | null;
        References: string[];
    }
}

export const LicenseUpdated: React.FC<EventModalContentProps<LicenseUpdatedEventModel>> = (props: EventModalContentProps<LicenseUpdatedEventModel>) => {
    const { item, subTitle } = props;
    const renderNote = () => {
        if(!item) return null;
        let note = ''
        try {
            const noteObj: NoteObj = JSON.parse(item.note);
            const {Parameter: {Name, AdditionalNote}, Key} = noteObj
            note = fmtMsg({ id: Key }, {name: Name, additionalNote: AdditionalNote})
        } catch (error) {   
            note = item.note
        }
        return note;
    }
    return (
        <div className='license-updated'>
            <FormItemsLayout colTotal={1} >
                <LableText
                    lable={fmtMsg({id:SchoolLocale.CIMSChangeEventClassName})}
                    text={subTitle}
                />
                {renderNote() && <p>{fmtMsg({id:SchoolLocale.LicenseNote})} {renderNote()}</p>}
            </FormItemsLayout>
            <LicenseCountComparer item={item} />
        </div>
    )
}

const LableText = (props: {lable: string, text: string}) => {
    return (
        <FormItem {...FormItemLayout4LableText}>
            <span className="ant-form-text lable-text">{props.lable}:</span>
            <span className="ant-form-text lable-text">{props.text}</span>
        </FormItem>
    )
}
