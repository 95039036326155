import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { EventModalContentProps, ChangeEventModel } from '@app/service/cims';
import { fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout, FormItemLayout4Footer } from './cims-consts';

export const CampusInfo: React.FC<EventModalContentProps<ChangeEventModel>> = (props: EventModalContentProps<ChangeEventModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    return (
        <div className='campus-info'>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusName})}>
                    <span className="ant-form-text">{data.name}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusPhone})}>
                    <span className="ant-form-text">{data.phone}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusFax})}>
                    <span className="ant-form-text">{data.fax}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusState})}>
                    <span className="ant-form-text">{data.state}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusCity})}>
                    <span className="ant-form-text">{data.city}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusPostalCode})}>
                    <span className="ant-form-text">{data.postalCode}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={1} >
                <FormItem {...FormItemLayout4Footer} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCampusFullAddress})}>
                    <span className="ant-form-text">{data.fullAddress}</span>
                </FormItem>
            </FormItemsLayout>
        </div>
    )
}