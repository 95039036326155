import { ColumnLink, WijmoGrid } from "@app/components";
import { GLTable } from "@app/components/admin/gl-table";
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { PendingChangeModel, PendingChangePropsModel } from "@app/service/cims";
import { CampusModel } from "@app/service/schools";
import { ChangeLogType, DateHelper, fmtMsg, GSAdminAction, GSSchoolAction, PendingChangeField } from "@app/util";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { PendingChangeFieldMapped2GridColumn, getChangeFields2Columns, canApproveDeny } from "./cims-consts";
import { toggleClass } from "wijmo/wijmo";
import { CellType }  from "wijmo/wijmo.grid";
import { Icon } from "antd";

const { Grid, Column } = WijmoGrid;

class ClassTable extends GLTable<PendingChangeModel> {}

interface ClassesTableProps extends Partial<RouteComponentProps> {
    loading?: boolean;
    campus?: CampusModel;
    campusClasses?: PendingChangeModel[];
    campusClasses4Display?: PendingChangeModel[];
    onQuery?: (query) => void;
    // onExport?: (query: { campusName: string; columns: any[]; exportData: PendingChangeModel[] }) => void;
    onApprove?: (query) => void;
}

export const ClassesTable: React.FC<ClassesTableProps> = withRouter((props: ClassesTableProps) => {
    const { loading, campus, campusClasses, campusClasses4Display, onQuery, onApprove, history } = props;
    // let exportData = campusClasses;

    // function getUnitPlanColumns(is4Export = false) {
    //     return [...Array(8).keys()].map((unitIndex) => {
    //         return {
    //             title: is4Export
    //                 ? SchoolLocale[`CIMSPendingChangesUnitColumn${unitIndex + 1}`]
    //                 : fmtMsg({ id: SchoolLocale[`CIMSPendingChangesUnitColumn${unitIndex + 1}`] }),
    //             dataIndex: PendingChangePropsModel[`plansIndexed${unitIndex + 1}`],
    //             sorter: (a, b) => {
    //                 const startDateA = a[`plansIndexed${unitIndex + 1}`],
    //                     startDateB = b[`plansIndexed${unitIndex + 1}`];
    //                 return stringCompare4Date(startDateA, startDateB);
    //             },
    //             width: 110,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(
    //                     DateHelper.formatDate2Local(campusClass.plans[unitIndex]),
    //                     campusClass,
    //                     PendingChangePropsModel[`plansIndexed${unitIndex + 1}`]
    //                 );
    //             },
    //         };
    //     });
    // }

    // function getHeader(campusClasses: PendingChangeModel[]) {
    //     return campusClasses.length > 0 ? (
    //         <div className="campus-class-filter">
    //             <Input.Search
    //                 placeholder={fmtMsg({ id: SchoolLocale.CIMSClassChangesSearchPlaceHolder })}
    //                 onSearch={(value, event) => onQuery({ campusId: campus.id, filter: value })}
    //             />
    //             <div
    //                 className="campus-classes-export"
    //                 onClick={() => {
    //                     maskThrottle();
    //                     onExport({ campusName: campus.name, columns: getColumns(true), exportData });
    //                 }}
    //             >
    //                 <Icon type="download" />
    //                 <span>{fmtMsg({ id: SchoolLocale.CIMSClassChangesExportAction })}</span>
    //             </div>
    //         </div>
    //     ) : null;
    // }

    // function renderCampusClassField(text, campusClass, columnName, reactNode?) {
    //     const columns = getChangeFields2Columns(campusClass, PendingChangeFieldMapped2GridColumn);
    //     const className = columns.indexOf(columnName) >= 0 && GLGlobal.isActionValid(GSAdminAction.ListPendingChanges) ? "changed-field" : "";
    //     return {
    //         props: {
    //             className: `${className}`,
    //         },
    //         children: reactNode ? reactNode : text,
    //     };
    // }

    // function getColumns(is4Export = false): ColumnProps<PendingChangeModel>[] {
    //     let columns: ColumnProps<PendingChangeModel>[] = [
    //         {
    //             title: is4Export ? SchoolLocale.CIMSPendingChangesClassColumn : fmtMsg({ id: SchoolLocale.CIMSPendingChangesClassColumn }),
    //             dataIndex: PendingChangePropsModel.schoolClassName,
    //             sorter: (a, b) => stringCompare(a.schoolClassName, b.schoolClassName),
    //             fixed: "left",
    //             width: 200,
    //             className: "navigation-col",
    //             render: (text, campusClass, index) => {
    //                 const { regionId, schoolId, campusId, schoolClassId: classId } = campusClass;
    //                 return renderCampusClassField(
    //                     text,
    //                     campusClass,
    //                     PendingChangePropsModel.schoolClassName,
    //                     <a href={`${GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId })}`}>
    //                         {" "}
    //                         <span>{text}</span>{" "}
    //                     </a>
    //                 );
    //             },
    //         },
    //         {
    //             title: is4Export ? SchoolLocale.CIMSPendingChangesGradeColumn : fmtMsg({ id: SchoolLocale.CIMSPendingChangesGradeColumn }),
    //             dataIndex: PendingChangePropsModel.grade,
    //             sorter: (a, b) => stringCompare(a.grade, b.grade),
    //             width: 100,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.grade);
    //             },
    //         },
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesLicenseTypeColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesLicenseTypeColumn }),
    //             dataIndex: PendingChangePropsModel.licenseType4Display,
    //             sorter: (a, b) => stringCompare(a.licenseType4Display, b.licenseType4Display),
    //             width: 125,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.licenseType4Display);
    //             },
    //         },
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesStudentCountColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesStudentCountColumn }),
    //             dataIndex: PendingChangePropsModel.studentCount,
    //             sorter: (a, b) => numberCompare(a.studentCount, b.studentCount),
    //             width: 80,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.studentCount);
    //             },
    //         },
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesStartDateColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesStartDateColumn }),
    //             dataIndex: PendingChangePropsModel.startDate4Display,
    //             sorter: (a, b) => stringCompare4Date(a.startDate4Display, b.startDate4Display),
    //             width: 120,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(
    //                     DateHelper.formatDate2Local(campusClass.startDate),
    //                     campusClass,
    //                     PendingChangePropsModel.startDate4Display
    //                 );
    //             },
    //         },
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesTsiPerWeekColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesTsiPerWeekColumn }),
    //             dataIndex: PendingChangePropsModel.tsiLessonsPerWeek,
    //             sorter: (a, b) => numberCompare(a.tsiLessonsPerWeek, b.tsiLessonsPerWeek),
    //             width: 120,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.tsiLessonsPerWeek);
    //             },
    //         },
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesStartUnitColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesStartUnitColumn }),
    //             dataIndex: PendingChangePropsModel.startUnit4Display,
    //             sorter: (a, b) => numberCompare(a.startUnit4Display, b.startUnit4Display),
    //             width: 60,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.startUnit4Display);
    //             },
    //         },
    //         {
    //             title: is4Export ? SchoolLocale.CIMSPendingChangesEndUnitColumn : fmtMsg({ id: SchoolLocale.CIMSPendingChangesEndUnitColumn }),
    //             dataIndex: PendingChangePropsModel.endUnit4Display,
    //             sorter: (a, b) => numberCompare(a.endUnit4Display, b.endUnit4Display),
    //             width: 60,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(text, campusClass, PendingChangePropsModel.endUnit4Display);
    //             },
    //         },
    //         ...getUnitPlanColumns(is4Export),
    //         {
    //             title: is4Export
    //                 ? SchoolLocale.CIMSPendingChangesUpdateDateColumn
    //                 : fmtMsg({ id: SchoolLocale.CIMSPendingChangesUpdateDateColumn }),
    //             dataIndex: PendingChangePropsModel.updateTime4Display,
    //             sorter: (a, b) => stringCompare4Date(a.updateTime4Display, b.updateTime4Display),
    //             width: 150,
    //             render: (text, campusClass, index) => {
    //                 return renderCampusClassField(
    //                     campusClass.updateTime ? DateHelper.formatDateTime2Local(campusClass.updateTime, true, null, true) : "",
    //                     campusClass,
    //                     PendingChangePropsModel.updateTime4Display
    //                 );
    //             },
    //         },
    //     ];
    //     if (!is4Export) {
    //         columns.push({
    //             title: "",
    //             fixed: "right",
    //             width: 120,
    //             render: (text, campusClass, index) => {
    //                 const hasSpecialChanges =
    //                     campusClass.changeFields.filter(
    //                         (changes) =>
    //                             changes.changeField == PendingChangeField.AddClass ||
    //                             changes.changeField == PendingChangeField.DisableClass ||
    //                             changes.changeField == PendingChangeField.EnableClass
    //                     ).length > 0;
    //                 const canApprove4Coach =
    //                     !hasSpecialChanges &&
    //                     campusClass.changeFields.filter((changes) =>
    //                         canApproveDeny(ChangeLogType.SchoolClass, changes.changeField as PendingChangeField)
    //                     ).length > 0;
    //                 return campusClass.changeFields.length > 0 &&
    //                     (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClass) ||
    //                         (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClassUnitPlan) && canApprove4Coach)) ? (
    //                     <a
    //                         onClick={(e) =>
    //                             onApprove({ schoolId: campusClass.schoolId, campusId: campusClass.campusId, classId: campusClass.schoolClassId })
    //                         }
    //                     >
    //                         <FormattedMessage id={SchoolLocale.CIMSPendingChangesApproveAction} />
    //                     </a>
    //                 ) : (
    //                     ""
    //                 );
    //             },
    //         });
    //     }
    //     return columns;
    // }

    // const classTableClassName = {
    //     "campus-classes": true,
    //     "have-data": campusClasses.length > 0,
    //     "no-data": campusClasses.length == 0,
    // };

    const renderWijmoRow = (s, e) => {
        if (e.panel.cellType == CellType.Cell && s.rows[e.row].dataItem && GLGlobal.isActionValid(GSAdminAction.ListPendingChanges)) {
            const columns = getChangeFields2Columns(s.rows[e.row].dataItem, PendingChangeFieldMapped2GridColumn);
            toggleClass(e.cell, 'haschanges', columns.indexOf(s.columns[e.col].binding) >= 0);    
        }
    }
    return (
        <Grid
            loading={loading}
            itemsSource={campusClasses4Display}
            pagination={false}
            search
            searchOptions={{
                label: fmtMsg({ id: SchoolLocale.CIMSClassChangesSearchPlaceHolder }),
                onSearch: (value) => onQuery({ campusId: campus.id, filter: value }),
            }}
            allowExport
            exportOptions={{
                filename: campus.name,
                buttonIcon: <Icon className="export-icon" type="download"/>,
                buttonLocaleId: SchoolLocale.ClassListExport,
                noDataMessage: fmtMsg({ id: GSAdminLocale.RegionSchoolsNoCampusExport }),
            }}
            stickyHeaders={false}
            formatItem={renderWijmoRow}
        >
            <Column
                binding={PendingChangePropsModel.schoolClassName}
                header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesClassColumn })}
                render={(text, campusClass) => {
                    const { regionId, schoolId, campusId, schoolClassId: classId } = campusClass;
                    return (
                        <ColumnLink history={history} url={`${GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId })}`}>
                            {text}
                        </ColumnLink>
                    );
                }}
            />
            <Column binding={PendingChangePropsModel.grade} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesGradeColumn })} />
            <Column binding={PendingChangePropsModel.curriculumType4Display} header={fmtMsg({ id: SchoolLocale.ClassCurriculumType })} />
            <Column binding={PendingChangePropsModel.studentCount} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesStudentCountColumn })} />
            <Column binding={PendingChangePropsModel.startDate4Display} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesStartDateColumn })} align="right"/>
            <Column binding={PendingChangePropsModel.tsiLessonsPerWeek} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesTsiPerWeekColumn })} />
            <Column binding={PendingChangePropsModel.startUnit4Display} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesStartUnitColumn })} />
            <Column binding={PendingChangePropsModel.endUnit4Display} header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesEndUnitColumn })} />
            {Array(8)
                .fill(null)
                .map((a, index) => {
                    const unit = index + 1;
                    return (
                        <Column
                            key={unit}
                            binding={PendingChangePropsModel[`plansIndexed${unit}`]}
                            header={fmtMsg({ id: SchoolLocale[`CIMSPendingChangesUnitColumn${unit}`] })}
                            render={(text, campusClass) => {
                                return DateHelper.formatDate2Local(campusClass.plans[index]);
                            }}
                            align="right"
                        />
                    );
                })}
            <Column
                binding={PendingChangePropsModel.updateTime4Display}
                header={fmtMsg({ id: SchoolLocale.CIMSPendingChangesUpdateDateColumn })}
                render={(text, campusClass) => {
                    return campusClass.updateTime ? DateHelper.formatDateTime2Local(campusClass.updateTime, true, null, true) : "";
                }}
                align="right"
            />
            <Column
                render={(text, campusClass) => {
                    const hasSpecialChanges =
                        campusClass.changeFields.filter(
                            (changes) =>
                                changes.changeField == PendingChangeField.AddClass ||
                                changes.changeField == PendingChangeField.PromoteClass ||
                                changes.changeField == PendingChangeField.DisableClass ||
                                changes.changeField == PendingChangeField.EnableClass
                        ).length > 0;
                    const canApprove4Coach =
                        !hasSpecialChanges &&
                        campusClass.changeFields.filter((changes) =>
                            canApproveDeny(ChangeLogType.SchoolClass, changes.changeField as PendingChangeField)
                        ).length > 0;
                    return campusClass.changeFields.length > 0 &&
                        (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClass) ||
                            (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClassUnitPlan) && canApprove4Coach)) ? (
                        <a
                            onClick={(e) =>
                                onApprove({ schoolId: campusClass.schoolId, campusId: campusClass.campusId, classId: campusClass.schoolClassId })
                            }
                        >
                            {fmtMsg({ id: SchoolLocale.CIMSPendingChangesApproveAction })}
                        </a>
                    ) : (
                        ""
                    );
                }}
            />
        </Grid>
    );

    // return campusClasses4Display && campusClasses4Display instanceof Array ? (
    //     <ClassTable
    //         className={classNames(classTableClassName)}
    //         rowKey="index"
    //         showHeader={campusClasses.length > 0}
    //         loading={loading}
    //         dataSource={campusClasses4Display}
    //         title={() => getHeader(campusClasses)}
    //         columns={getColumns()}
    //         pagination={false}
    //         onChange={(pagination, filters, sorter, extra) => (exportData = extra.currentDataSource)}
    //         //scroll={{x:5000, y:500}}
    //         scroll={{ x: "max-content" }}
    //     />
    // ) : (
    //     <Spin spinning={loading} />
    // );
});
