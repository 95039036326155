import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { EventModalContentProps, StudentMovementModel, StudentModel } from '@app/service/cims';
import { formatName, fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout } from './cims-consts';

export const StudentMovement: React.FC<EventModalContentProps<StudentMovementModel>> = (props: EventModalContentProps<StudentMovementModel>) => {
    const { loading } = props;
    if (loading && props.item.students) {
        props.item.students.map((student) => {
            student.name = formatName(student.englishName, student.name);
        })
    }
    const { students, licenseCount } = props.item;
    return (
        <div className='student-movement'>
            {(students || licenseCount) &&
                <FormItemsLayout colTotal={2} >
                    <FormItem {...FormItemLayout}>
                        <StudentMovementMessage {...props} />
                    </FormItem>
                </FormItemsLayout>}
        </div>
    )
}

export const StudentMovementMessage = (props: EventModalContentProps<StudentMovementModel>) => {
    const { item } = props;
    const messageId = item.licenseCount ? SchoolLocale.CIMSMoveUnregisteredStudentEventMessage : SchoolLocale.CIMSMoveStudentEventMessage;
    const students = item.students ? item.students.map(student=> student.name).join(',') : null;
    const messageParams = item.licenseCount ?
        {licenseCount: item.licenseCount, previousClassName: item.previousClassName, currentClassName: item.currentClassName} :
        {students, previousClassName: item.previousClassName, currentClassName: item.currentClassName};
    const message = fmtMsg({id:messageId}, messageParams);
    return <span className="ant-form-text" dangerouslySetInnerHTML={{__html: message}}/>
}
