import React, { Component } from 'react';
import { Divider } from "antd-min";
import { FormItemsLayout } from 'gl-commonui';
import { fmtMsg, subscriptionTypeUsage } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout4Licenses } from './cims-consts';
import { LicenseChangeTypeContentProps, LicenseUpdatedEventModel, LicenseChangeApproveDenyEventModel } from '@app/service/cims';
import { ArrowRightIcon } from '@app/components/svgicon';

export const LicenseCountInlineComparer: React.FC<LicenseChangeTypeContentProps<LicenseChangeApproveDenyEventModel | LicenseUpdatedEventModel>> = (props:LicenseChangeTypeContentProps<LicenseChangeApproveDenyEventModel | LicenseUpdatedEventModel>) => {
    const { item } = props;
    const {currentLicenses, previousLicenses} = 'currentLicenses' in item ? item as LicenseUpdatedEventModel : {currentLicenses: null, previousLicenses: null};
    const {currentStatus, previousStatus} = 'currentStatus' in item ? item as LicenseChangeApproveDenyEventModel : {currentStatus: null, previousStatus: null};
    const current = currentLicenses || currentStatus;
    const previous = previousLicenses ||  previousStatus;
    const digitalChanged = current &&  previous && current.newDigitalLicenses != previous.newDigitalLicenses;
    const textbookChanged = current &&  previous && current.newTextbookLicenses != previous.newTextbookLicenses;
    const usage = item.subscriptionTypeUsage; 
    return (
        <div className='license-count-inline-compare'>
            <div className='previous-part'>
                {usage != subscriptionTypeUsage.Standard && 
                <>
                    <div className='previous-part-label'>
                        <span className="ant-form-text">{fmtMsg({id:SchoolLocale.CIMSChangeEventStudentCount})}:</span> 
                    </div>
                    <div className='previous-part-value'>
                        <span className="ant-form-text">{previous && previous.newStudentCount}</span>
                    </div>
                </>}
                {usage == subscriptionTypeUsage.Standard && 
                <div className="license-count-digital-textbook">
                    {digitalChanged && <div className="license-count-digital">
                        <div className='previous-part-label'>
                            <span className="ant-form-text">{fmtMsg({id:SchoolLocale.CIMSChangeEventDigitalLicense})}:</span> 
                        </div>
                        <div className='previous-part-value'>
                            <span className="ant-form-text">{previous && previous.newDigitalLicenses}</span>                            
                        </div>
                    </div>}
                    {textbookChanged && <div className="license-count-textbook">
                        <div className='previous-part-label'>
                            <span className="ant-form-text">{fmtMsg({id:SchoolLocale.CIMSChangeEventTextbookLicense})}:</span> 
                        </div>
                        <div className='previous-part-value'>
                            <span className="ant-form-text">{previous && previous.newTextbookLicenses}</span>
                        </div>
                    </div>}
                </div>}
            </div>
            <ArrowRightIcon className='previous-current-arrow' />
            <div className='current-part'>
                {usage != subscriptionTypeUsage.Standard && 
                <div className='current-part-value'>
                    <span className="ant-form-text">{current && current.newStudentCount}</span>
                </div>}

                {usage == subscriptionTypeUsage.Standard && 
                <div className='current-part-value'>
                    {digitalChanged && 
                    <div className='digital-value'>
                        <span className="ant-form-text">{current && current.newDigitalLicenses}</span>
                    </div>}
                    {textbookChanged && 
                    <div className='textbook-value'>
                        <span className="ant-form-text">{current && current.newTextbookLicenses}</span>
                    </div>}
                </div>} 
            </div>
        </div>
    )
}