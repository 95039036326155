import React, { Component } from 'react';
import { Table } from "antd-min";
import { EventModalContentProps, LicenseChangeApproveDenyEventModel, ChangeEventPropsModel, LicenseChangePropsModel, LicenseChangeTypeContentProps } from '@app/service/cims';
import { fmtMsg, EventType, LicenseEditNoteType, ClassHelper, EventInfo, formatName } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { LicenseCountInlineComparer } from './license-count-inline-comparer';
import { StudentMovementMessage, StudentRemovedMessage } from './index';
import { GLUtil } from 'gl-commonui/lib/utility/utility';
import { PathConfig } from '@app/config/pathconfig';
import { GLRouteComponentProps, withRouter } from 'gl-commonui';

export const LicenseChange4ApproveDeny: React.FC<EventModalContentProps<LicenseChangeApproveDenyEventModel>> = (props: EventModalContentProps<LicenseChangeApproveDenyEventModel>) => {
    const { item, verificationCIMSId, loading } = props;
    if (loading && item instanceof Array) {
        item.map((i: any) => {
            // case move students
            if (i.students) {
                i.students.map((student: any) => {
                    student.name = formatName(student.englishName, student.name);
                })
            }
            // case remove student
            if (i.student) {
                i.student.name = formatName(i.student.englishName, i.student.name);
            }
        })
    }
    return ( item instanceof Array ?
        <div className='license-change-approve-deny'>
            <LicenseChangeTable logs={item} verificationCIMSId={verificationCIMSId} />
        </div> : null
    )
}

export const LicenseChangeType: Map<EventInfo, React.FC<LicenseChangeTypeContentProps<LicenseChangeApproveDenyEventModel>>> = new Map([
    [EventInfo.PendingChangesChangeLicense, LicenseCountInlineComparer],
    [EventInfo.PendingChangesMoveStudent, StudentMovementMessage],
    [EventInfo.PendingChangesRemoveStudent, StudentRemovedMessage],
    [EventInfo.PendingChangesMoveUnregisteredStudent, StudentMovementMessage],
]);

interface LicenseChangeTableProp{
    logs: Array<any>,
    verificationCIMSId: boolean
}

const LicenseChangeTable = withRouter((props: LicenseChangeTableProp & GLRouteComponentProps) => {
    const { logs, verificationCIMSId, match } = props

    const getStudentDetailPath = (student: any) => {        
        const { params: { regionId, schoolId } } = match;

        if (!student.campusId || !student.classId || !regionId || !schoolId) {
            return null;
        }

        return GLUtil.pathStringify(
            PathConfig.StudentReport,
            {
                regionId,
                schoolId,
                campusId: student.campusId,
                classId: student.classId,
                studentId: student.id
            }
        )
    }
    const renderLicenseEditNote = (editNoteType, editNote) => {
        if (!editNote) {
            return null;
        }
        const students = ClassHelper.getLicenseEditNoteStudentList(editNoteType, editNote);
        const isManyStudents = students && students.length > 1;
        const isOneStudent = students && students.length === 1;
        const studentNameWithDetail = isOneStudent && students[0].campusId && students[0].classId ?
            `<a href=${getStudentDetailPath(students[0])}>${students[0].name} (<i>${students[0].englishName}</i>)</a>` :
            isOneStudent && students[0] ? `${students[0].name} <i>(${students[0].englishName})</i>` : null;
        return <>
            <span dangerouslySetInnerHTML={{__html: ClassHelper.getLicenseEditNoteLocalization(editNoteType, editNote, studentNameWithDetail)}} />
            {
                isManyStudents && <ul>
                    {students.map((student) => {
                        return student.campusId && student.classId ?
                            <li><a href={getStudentDetailPath(student)}>{student.name} (<i>{student.englishName}</i>)</a></li> :
                            <li>{student.name} (<i>{student.englishName}</i>)</li>
                    })}
                </ul>
            }
        </>
    }

    return (
        <Table
            rowClassName={(record) => verificationCIMSId && record.id === verificationCIMSId ? "table-row-highlight": ""}
            rowKey='index'
            pagination={false}
            scroll={{y: 200}}
            dataSource={logs}
            columns={[
                {
                    title: fmtMsg({id:SchoolLocale.CIMSLicenseChangeChangeTypeColumn}),
                    dataIndex: LicenseChangePropsModel.eventType4Display,
                    width: '15%',
                },
                {
                    title: fmtMsg({id:SchoolLocale.CIMSLicenseChangeDetailColumn}),
                    dataIndex: LicenseChangePropsModel.detail,
                    width: '30%',
                    render: (text, log: LicenseChangeApproveDenyEventModel, index) => {
                        const ChangeType = LicenseChangeType.has(log.eventType) ? LicenseChangeType.get(log.eventType) : null;
                        
                        if(log.eventType == EventInfo.PendingChangesRemoveStudent)
                        {
                            const ChangeTypeLicense = LicenseChangeType.has(EventInfo.PendingChangesChangeLicense) ? LicenseChangeType.get(EventInfo.PendingChangesChangeLicense) : null;
                            return <>
                            {log.previousStatus != null && log.currentStatus != null && log.shouldCombine ? <ChangeTypeLicense item = {log} /> : null}
                            <ChangeType item = {log} />
                            </>
                        }
                        else
                        {
                            return <ChangeType item = {log} />
                        }
                    }
                },
                {
                    title: fmtMsg({id:SchoolLocale.CIMSLicenseChangeNotesColumn}),
                    dataIndex: LicenseChangePropsModel.digitalLicenseEditNote,
                    width: '25%',
                    className: 'license-change-note',
                    render: (text, log: LicenseChangeApproveDenyEventModel, index) => {
                        if ((log.digitalLicenseEditNoteType == LicenseEditNoteType.User || log.textbookLicenseEditNoteType == LicenseEditNoteType.User)
                            && (log.digitalLicenseEditNote || log.textbookLicenseEditNote)) {
                            return (<>
                                {
                                    renderLicenseEditNote(log.textbookLicenseEditNoteType, log.textbookLicenseEditNote)
                                }
                                {
                                    renderLicenseEditNote(log.digitalLicenseEditNoteType, log.digitalLicenseEditNote)
                                }
                            </>);
                        } else {
                            return <span></span>;
                        }
                    }
                },
                {
                    title: fmtMsg({id:SchoolLocale.CIMSChangeEventModifyby}),
                    dataIndex: ChangeEventPropsModel.createdBy,
                    width: '12%',
                },
                {
                    title: fmtMsg({id:SchoolLocale.CIMSChangeEventModifyDate}),
                    dataIndex: ChangeEventPropsModel.createdDate,
                    width: '18%',
            }]}
        />
    )
});
