import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { EventModalContentProps, ChangeEventModel } from '@app/service/cims';
import { fmtMsg, SchoolHelper, DateHelper, LicenseTypeValueNameMap, CONSTS } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout } from './cims-consts';

export const ClassInfo: React.FC<EventModalContentProps<ChangeEventModel>> = (props: EventModalContentProps<ChangeEventModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    const schoolCurriculumType = SchoolHelper.generateSchoolCurriculumType();
    return (
        <div className='class-info'>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventAgeGrade})}>
                    <span className="ant-form-text">{data.age}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventCurriculumType})}>
                    <span className="ant-form-text">{schoolCurriculumType.get(`${data.curriculumType}`)}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventTSIPerweek})}>
                    <span className="ant-form-text">{data.tsiLessonsPerWeek}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventLicenseType})}>
                    <span className="ant-form-text">{data.licenseType && fmtMsg({id: CONSTS.LicenseType.get(data.licenseType)})}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventStartUnit})}>
                    <span className="ant-form-text">{data.startUnit && Math.abs(data.startUnit)}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventEndUnit})}>
                    <span className="ant-form-text">{data.endUnit && Math.abs(data.endUnit)}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventStartDate})}>
                    <span className="ant-form-text">{DateHelper.formatDate2Local(data.startDate)}</span>
                </FormItem>
                <FormItem {...FormItemLayout} label={fmtMsg({id:SchoolLocale.CIMSChangeEventEndDate})}>
                    <span className="ant-form-text">{DateHelper.formatDate2Local(data.endDate)}</span>
                </FormItem>
            </FormItemsLayout>
        </div>
    )
}